
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  // import SubscriptionsRepository from '@/api/subscriptionsRepository'
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { Ticket } from "@/models/tickets";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { lastTicketActivity } from "@/utils/dataExtractors"

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.tickets.pagination.totalItems)

      const getItems = async(ctx: BvTableCtxObject) => {
        const ticketQuery = {
          'filter[archived]': false,
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-createdAt',
        }
        await root.$store.dispatch('tickets/load', ticketQuery)
        return root.$store.state.tickets.data
        // const subscriptionIds = extractRelationshipIds(ticketResponse, ['subscriptions']).subscriptions
        // const subs = await getSubscriptions(subscriptionIds)
        // const ticketsWithSubs = ticketResponse.data.map((t)=>{
        //   const subList = t.relationships.subscriptions.data.map((sub: any) => subs[sub.id])
        //   t.attributes.subscriptions = subList
        //   return t
        // })
        // return ticketsWithSubs
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.subject",
          title: "Betreff",
          sortable: true,
          options: {
            hrefFunction: (data: any) => `/${data.item.type}/${data.item.id}`,
            contentFunction: (data: any) => (data.item.attributes.subject || 'Kein Betreff')
          }
        },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "ticketableType",
          title: "Typ",
          sortable: true,
          options: {
            contentFunction: (data:any) => {
              return data.item.attributes.ticketableType
            },
            hrefFunction: (data: any) => {
              const poly = data.item.relationships.ticketable.data
              return `/${poly.type}/${poly.id}`
            },
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.category",
          title: "Kategorie",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/IconCell.vue"),
          key: "attributes.archived",
          title: "Archiviert",
          sortable: true,
          options: {
            mappedIcons: [
              {
                value: false,
                stack: [
                  {
                    icon: 'times',
                    class: 'text-muted'
                  }
                ]
              },
              {
                value: true,
                stack: [
                  {
                    icon: 'check',
                    class: 'text-muted'
                  }
                ]
              },
            ]
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "messageCount",
          title: "Nachrichten",
          sortable: true,
          options: {
            contentFunction: (data: any) => data.item.attributes.messages.length
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "lastMessage",
          title: "Letzte Änderung",
          sortable: true,
          options: {
            type: 'datetime',
            contentFunction: (data: any) => lastTicketActivity(data.item)
          }
        },
      ])

      const titleActions = []

      return {
        titleActions,
        getItems,
        totalItems,
        columns
      }
    }
  })
